import { navigate } from "gatsby";
import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from "../components/controls/card";
import ContactForm from "../components/forms/contact-form";
import Root from "../components/layout/root";
import Edge from "../data/edge";
import Vehicle from "../data/vehicle";

const Vehicles = ({ pageContext }: any) => {
  if (
    pageContext === undefined ||
    (pageContext !== undefined && pageContext.car == undefined)
  )
    return <div />;

  const galleryImages = pageContext.car.images.map((x: any) => {
    return {
      original: x,
      thumbnail: x
    };
  });

  return (
    <Root>
      <div className="container">
        <div className="w-full flex items-start h-auto lg:h-auto mt-10 mx-auto">
          <div
            id="profile"
            className="mx-auto h-auto w-full lg:w-1/2 rounded-lg lg:rounded-l-lg lg:rounded-r-none shadow-2xl bg-white lg:mx-0"
          >
            <div className="p-4 md:p-12 text-center lg:text-left">
              <div className="block lg:hidden shadow-xl mx-auto -mt-10 bg-cover bg-center">
                <ImageGallery items={galleryImages} />
              </div>

              <h1 className="text-3xl text-tertiary-500 font-bold pt-8 lg:pt-0">
                {`${pageContext.car.brand} ${
                  pageContext.car.model === null ? "" : pageContext.car.model
                }`}
              </h1>
              {pageContext.car.version === null ? null : (
                <h2 className="text-4x1 text-tertiary-400 pt-8 lg:pt-0">
                  {`${pageContext.car.version}`}
                </h2>
              )}
              <div className="mx-auto flex-wrap w-full pt-3 border-b-2 border-highlight-500 " />
              <div className="grid grid-cols-2 flex-wrap mx-auto w-4/5 pt-3">
                <p className="pt-2 text-gray-600 text-lg flex items-center justify-center lg:justify-start">
                  <svg
                    className="h-6 fill-current text-tertiary-500 pr-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M1 4c0-1.1.9-2 2-2h14a2 2 0 012 2v14a2 2 0 01-2 2H3a2 2 0 01-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z" />
                  </svg>
                  {`${pageContext.car.month}/${pageContext.car.year}`}
                </p>
                <p className="pt-2 text-gray-600 text-lg flex items-center justify-center lg:justify-start">
                  <svg
                    className="h-6 fill-current text-blue-900 pr-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 20a10 10 0 110-20 10 10 0 010 20zm-5.6-4.29a9.95 9.95 0 0111.2 0 8 8 0 10-11.2 0zm6.12-7.64l3.02-3.02 1.41 1.41-3.02 3.02a2 2 0 11-1.41-1.41z" />
                  </svg>
                  {pageContext.car.kms + " km"}
                </p>
              </div>
              <div className="grid grid-cols-2 flex-wrap mx-auto w-4/5 pt-3">
                <p className="pt-2 text-gray-600 text-lg flex items-center justify-center lg:justify-start">
                  <svg
                    className="h-6 fill-current text-blue-900 pr-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M3.94 6.5L2.22 3.64l1.42-1.42L6.5 3.94c.52-.3 1.1-.54 1.7-.7L9 0h2l.8 3.24c.6.16 1.18.4 1.7.7l2.86-1.72 1.42 1.42-1.72 2.86c.3.52.54 1.1.7 1.7L20 9v2l-3.24.8c-.16.6-.4 1.18-.7 1.7l1.72 2.86-1.42 1.42-2.86-1.72c-.52.3-1.1.54-1.7.7L11 20H9l-.8-3.24c-.6-.16-1.18-.4-1.7-.7l-2.86 1.72-1.42-1.42 1.72-2.86c-.3-.52-.54-1.1-.7-1.7L0 11V9l3.24-.8c.16-.6.4-1.18.7-1.7zM10 13a3 3 0 100-6 3 3 0 000 6z" />
                  </svg>
                  {pageContext.car.hp + " Cv "}
                </p>
                <p className="pt-2 text-gray-600 text-lg flex items-center justify-center lg:justify-start">
                  <svg
                    className="h-6 fill-current text-blue-900 pr-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M17 12h-6v4h1v4H8v-4h1v-4H3v4h1v4H0v-4h1v-4a2 2 0 012-2h6V6H7V0h6v6h-2v4h6a2 2 0 012 2v4h1v4h-4v-4h1v-4z" />
                  </svg>
                  {pageContext.car.gearbox}
                </p>
              </div>
              <div className="grid grid-cols-2 flex-wrap mx-auto w-4/5 pt-3">
                <p className="pt-2 text-gray-600 text-lg flex items-center justify-center lg:justify-start">
                  <svg
                    className="h-6 fill-current text-blue-900 pr-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2 14v-3H1a1 1 0 01-1-1 1 1 0 011-1h1l4-7h8l4 7h1a1 1 0 011 1 1 1 0 01-1 1h-1v6a1 1 0 01-1 1h-1a1 1 0 01-1-1v-1H5v1a1 1 0 01-1 1H3a1 1 0 01-1-1v-3zm13.86-5L13 4H7L4.14 9h11.72zM5.5 14a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm9 0a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                  </svg>
                  {pageContext.car.seats + " lugares"}
                </p>
                <p className="pt-2 text-gray-600 text-lg flex items-center justify-center lg:justify-start">
                  <svg
                    className="h-6 fill-current text-blue-900 pr-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 18h1v2H0v-2h1V2c0-1.1.9-2 2-2h8a2 2 0 012 2v16zM3 2v6h8V2H3zm10 8h1a2 2 0 012 2v3a1 1 0 002 0v-5l-2-2V6l-2-2 1-1 5 5v7a3 3 0 01-6 0v-3h-1v-2z" />
                  </svg>
                  {pageContext.car.fuel === "gaz" ? "Gasolina" : "Gasoleo"}
                </p>
              </div>
              <h1 className="pt-8 text-lg font-bold">Observações:</h1>
              <p className="text-sm">{pageContext.car.obs}</p>

              <p className="pt-10 text-center text-highlight-500 text-secondary-600 w-full text-4xl font-bold underline">
                {`${Number.parseInt(pageContext.car.price as string)
                  .toFixed(0)
                  .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")} €`}
              </p>
            </div>
          </div>

          <div className="w-full invisible lg:visible lg:w-1/2 shadow-2xl">
            <ImageGallery items={galleryImages} />
          </div>
        </div>

        <p className="font-sans mt-10 text-tertiary-500 font-bold text-3xl text-center ">
          Equipamento
        </p>
        <div className="rounded shadow-xl ml-5 mr-5 mt-5">
          <ul className="flex flex-row flex-wrap list-disc list-inside list pb-5 px-5">
            {pageContext.car.equipmentlist === null ||
            pageContext.car.equipmentlist === "" ? (
              <p className="m-5">Nada a acrescentar.</p>
            ) : (
              (pageContext.car.equipmentlist as string)
                .split(",")
                .map((extra: string, index: number) => {
                  return (
                    <li
                      key={index}
                      className="w-full lg:w-1/3 xl:w-1/3 pl-2 pr-2"
                    >
                      {extra}
                    </li>
                  );
                })
            )}
          </ul>
        </div>
        <ContactForm
          className="p-10 justify-center"
          style={{ textAlign: "-webkit-center" }}
          onChange={() => {}}
          defaultSubject={`${pageContext.car.brand} ${
            pageContext.car.model === null ? "" : pageContext.car.model
          } ${
            pageContext.car.version === null ? "" : pageContext.car.version
          } (${pageContext.car.price}€)`}
        />
      </div>
      {pageContext.relatedCars !== undefined &&
      pageContext.relatedCars.length > 0 ? (
        <div className="w-full flex flex-col items-center h-auto lg:h-auto mt-10 mx-auto">
          <p className="font-sans mt-10 text-secondary-600 font-bold text-xl text-center">
            Também poderá ter interesse em:
          </p>
          <div className="flex flex-col lg:flex-row xl:flex-row w-full xl:w-5/6">
            {pageContext.relatedCars.map(
              (vehicle: Edge<Vehicle>, index: number) => {
                if (vehicle === null) return;
                return (
                  <Card
                    className={"flex-auto mx-3 my-3"}
                    key={index}
                    make={vehicle.node.brand}
                    model={vehicle.node.model}
                    year={parseInt(vehicle.node.year)}
                    price={parseInt(vehicle.node.price)}
                    mileage={parseInt(vehicle.node.kms)}
                    transmission={
                      vehicle.node.transmission === "manual" ? "manual" : "auto"
                    }
                    image={vehicle.node.images[0]}
                    onClick={() => {
                      navigate(`/vehicles/${vehicle.node.id}`);
                    }}
                  />
                );
              }
            )}
          </div>
        </div>
      ) : null}
    </Root>
  );
};

export default Vehicles;
